// import Right from '../../../assets/img/right.png';
import Left from '../../../assets/img/highlight-left.png';

import Right from '../../../assets/img/highlight-right.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
  },
  highlightSliderSection: {
    backgroundColor: '#B3DCF2',
  },
  exploreSectorSection: {
    padding: '40px 0',
    '& h3': {
      color: theme.palette.common.darkBlue,
      marginBottom: '25px',
      fontWeight: '300',
      fontSize: 20,
    },
  },
  slideItem: {
    display: 'flex !important',
    alignItems: 'center',
    height: '200px',
    padding: '0',
    '& figure': {
      margin: '0 0 0 0',
      '& img': {
        width: '100%',
      },
    },
    '& p': {
    },
    '& h2': {
      marginBottom: '7px',
      fontSize: 45,
    },
    '& > div': {
      width: '25%',
      '&:first-child': {
        width: '80%',
        paddingRight: '18px',
        paddingLeft: '18px',
        marginBottom: '15px',
        '& h3': {
          fontSize: 25,
          fontWeight: '800',
        },
        '& u': {
          display: 'inline-block',
          '& a': {
            color: theme.palette.common.white,
          },
        },
      },
      '&:last-child': {
        maxWidth: '200px',
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%',
        },
        [theme.breakpoints.down( 'sm' )]: {
          width: '33.33%',
        },
      },
      [theme.breakpoints.down( 'sm' )]: {
        width: '33.33%',
        display: 'inline-block',
        marginBottom: '25px',
        verticalAlign: 'top',
        '&:first-child': {
          width: '100%',
          marginBottom: '25px',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '50%',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      textAlign: 'center',
      '& h2': {
        fontSize: 25,
        lineHeight: '30px',
      },
      '& p': {
        marginTop: '15px',
      },
    },
  },
  sliderContainer: {
    display: 'block',
    height: '200px',
    [theme.breakpoints.down( 'md' )]: {
      maxWidth: '900px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '600px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '260px',
    },
  },
  nextArrowClass: {
    padding: '16px 8px',
    position: 'absolute',
    top: '50%',
    right: '-65px',
    width: '32px',
    background: `url( ${Right} )`,
    backgroundColor: '#B3DCF2',
    backgroundPosition: 'right',
    zIndex: '9',
    cursor: 'pointer',
    opacity: '0.8',
    fontSize: 50,
    backgroundSize: '50px',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    '&:before': {
      display: 'none',
    },
    '&:hover': {
      background: `url( ${Right} )`,
      backgroundPosition: ' right',
      backgroundSize: '50px',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down( 'sm' )]: {
      right: '-12px',
    },
  },
  prevArrowClass: {
    padding: '16px 8px',
    position: 'absolute',
    top: '50%',
    left: '-65px',
    width: '32px',
    backgroundPosition: ' 0 0',
    background: `url( ${Left} )`,
    zIndex: '9',
    cursor: 'pointer',
    opacity: '0.8',
    fontSize: 50,
    backgroundSize: '50px',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    '&:before': {
      display: 'none',
    },
    '&:hover': {
      background: `url( ${Left} )`,
      backgroundPosition: ' 0 0',
      backgroundSize: '50px',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down( 'sm' )]: {
      left: '-30px',
    },
  },
} );

export default styles;
