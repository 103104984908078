/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import styles from './styles';
import AboutBanner from '../../../assets/img/about-banner.png';

const Banner = ( props ) => {
  const { classes } = props;

  return (
    <div className={classes.welcomeSection}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.welcomeContent}>
          <div className={classes.welcomeText}>
            <Typography variant="h2">About DEP</Typography>
            <Typography component="p" className={classes.tagLine}>
              Know more about the platform
            </Typography>
            <Typography component="p">
              The Development Evidence Portal (DEP) is an expansive and growing repository of rigorous evidence on what works in international development in low- and middle income countries. The portal is a gateway to high-quality impact evaluations, systematic reviews and evidence gap maps, making it the most comprehensive resource available for this kind of evidence.
            </Typography>
          </div>
          <div role="button" tabIndex="0" className={classes.welcomeTextBlock}>
            <figure>
              <img src={AboutBanner} alt="" />
            </figure>
          </div>
        </div>
      </Container>
    </div>
  );
};

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Banner );
