import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import scrollToComponent from 'react-scroll-to-component';
import Button from '@material-ui/core/Button';
import ContentTypes from './content-types/content-types';
import styles from './styles';
import Loading from '../../components/ui/loading/loading';
import Banner from './banner/banner';
import Sponsors from './sponsors/sponsors';
import HighlightSlider from './highlight-slider/highlight-slider';
import { scrollToTop } from '../../utilities/commonFunctions';
import ScrollTop from '../../assets/img/up.png';

const ABOUT_PAGE = loader( '../../graphql/schema/home/about-us.graphql' );

const AboutUs = ( props ) => {
  const [showScrollTop, setShowScrollTop] = useState( false );
  const taxonomyRef = React.createRef();

  const { classes, location } = props;

  const { hash } = location;

  const scrollOptions = {
    align: 'top',
    duration: 500,
  };

  const { loading } = useQuery( ABOUT_PAGE );

  useEffect( () => {
    if ( hash === '#taxonomy' ) {
      scrollToComponent( taxonomyRef.current, scrollOptions );
    }
  }, [hash, scrollOptions, taxonomyRef, loading] );

  // Effect to handle scroll event and show/hide "Scroll to Top" button
  useEffect( () => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      // Show the scroll-to-top button if scrolled down more than 300px
      if ( scrollY > 300 ) {
        setShowScrollTop( true );
      } else {
        setShowScrollTop( false );
      }
    };

    window.addEventListener( 'scroll', handleScroll );

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener( 'scroll', handleScroll );
    };
  }, [] );

  if ( loading ) {
    return <Loading theme="fullHeight" />;
  }

  return (
    <Typography variant="body1" component="div">
      <Helmet>
        <title>About | 3ie</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.landingPage}>
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.backToSearch}>
            <Link to="/">
              <Typography component="p">
                Home
                <i className="fas fa-chevron-right" />
                About DEP
              </Typography>
            </Link>
          </div>
        </Container>
        <div className={classes.aboutContainer}>
          <Banner />
        </div>
      </Container>
      <HighlightSlider />

      <div className={classes.divider} />

      <Container maxWidth="lg" className={classes.landingPage}>
        <div className={classes.aboutContainer}>
          <Container maxWidth="lg" className={classes.container}>
            <ContentTypes />
          </Container>

          <Sponsors />
        </div>
      </Container>
      {showScrollTop && (
      <div className={classes.scrollTopWindow}>
        <Button onClick={() => { scrollToTop( 'smooth' ); }}><img src={ScrollTop} alt="" /></Button>
      </div>
      )}
    </Typography>
  );
};

AboutUs.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( AboutUs );
