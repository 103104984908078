import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Tab,
  Tabs,
  AppBar,
} from '@material-ui/core';
import TabPanel from '../tab-panel/tab-panel';
import styles from './styles';

/**
 * ContentTypes component
 *
 * @class ContentTypes
 * @extends {Component}
 */

const ContentTypes = ( props ) => {
  const { classes } = props;
  const [value, setValue] = useState( 0 );

  const handleChange = ( e, tabValue ) => {
    e.preventDefault();
    setValue( tabValue );
  };

  const tabsProps = ( index ) => ( {
    id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,
  } );

  return (
    <div className={classes.aboutTabs}>
      <div className={classes.aboutTabsMain}>
        <AppBar position="static" className={classes.aboutTabsButtonBox}>
          <Tabs
            className={classes.aboutTabsButton}
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            <Tab classes={{ selected: classes.selectedColor }} label="How to use DEP better" {...tabsProps( 0 )} />
            <Tab classes={{ selected: classes.selectedColor }} label="What's New" {...tabsProps( 1 )} />
            <Tab classes={{ selected: classes.selectedColor }} label="Testimonials" {...tabsProps( 2 )} />
            <Tab classes={{ selected: classes.selectedColor }} label="Taxonomy" {...tabsProps( 3 )} />
            <Tab classes={{ selected: classes.selectedColor }} label="Support Us" {...tabsProps( 4 )} />
            <Tab classes={{ selected: classes.selectedColor }} label="Show All" {...tabsProps( 5 )} />
          </Tabs>
        </AppBar>
        <TabPanel className={classes.tabContentContainer} value={value} index={0}>
          <div className={classes.tabContentHead}>
            <h3>How to use DEP better</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac, condimentum eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Curabitur lobortis tortor non vehicula ultricies. Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque.
              </p>
              <p>
                In tincidunt lobortis nisl a viverra. Duis tempus rhoncus mauris in auctor. Ut at augue sed purus consectetur efficitur. Proin lobortis scelerisque neque, eu varius dui. Etiam mollis massa vel neque venenatis fringilla. Cras ac odio elit. Vivamus euismod molestie
                porttitor. Sed at consequat est. Curabitur lobortis accumsan ipsum, nec tincidunt libero scelerisque in. Vivamus mollis, justo ut vehicula ultrices, nulla nisl maximus justo, quis condimentum lectus diam id odio. Nunc ultrices, nunc sed convallis eleifend, massa tellus congue tortor, sed euismod purus orci id arcu. Duis elementum, quam a sodales egestas, quam dolor hendrerit sapien, sit amet sollicitudin mauris erat non nunc.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabContentContainer} value={value} index={1}>
          <div className={classes.tabContentHead}>
            <h3>What&#39;s New</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac, condimentum eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque. Curabitur lobortis tortor non vehicula ultricies.
              </p>
              <p>
                Curabitur tincidunt ac ligula a molestie. Aenean libero magna, dictum ut libero sit amet, maximus sagittis ligula. Quisque ultrices auctor feugiat. Maecenas tempus facilisis tellus sed tincidunt. Sed porttitor sapien nulla, ut vestibulum neque finibus eu. Ut tempus eget orci sit amet blandit. Mauris ullamcorper pellentesque vestibulum.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabContentContainer} value={value} index={2}>
          <div className={classes.tabContentHead}>
            <h3>Testimonials</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac, condimentum eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Curabitur lobortis tortor non vehicula ultricies. Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque.
              </p>
              <p>
                In tincidunt lobortis nisl a viverra. Duis tempus rhoncus mauris in auctor. Ut at augue sed purus consectetur efficitur. Proin lobortis scelerisque neque, eu varius dui. Etiam mollis massa vel neque venenatis fringilla. Cras ac odio elit. Vivamus euismod
                molestie porttitor. Sed at consequat est. Curabitur lobortis accumsan ipsum, nec tincidunt libero scelerisque in. Vivamus mollis, justo ut vehicula ultrices, nulla nisl maximus justo, quis condimentum lectus diam id odio. Nunc ultrices, nunc sed convallis
                eleifend, massa tellus congue tortor, sed euismod purus orci id arcu. Duis elementum, quam a sodales egestas, quam dolor hendrerit sapien, sit amet sollicitudin mauris erat non nunc.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabContentContainer} value={value} index={3}>
          <div className={classes.tabContentHead}>
            <h3>Taxonomy</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac, condimentum eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Curabitur lobortis tortor non vehicula ultricies. Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque.
              </p>
              <p>
                In tincidunt lobortis nisl a viverra. Duis tempus rhoncus mauris in auctor. Ut at augue sed purus consectetur efficitur. Proin lobortis scelerisque neque, eu varius dui. Etiam mollis massa vel neque venenatis fringilla. Cras ac odio elit. Vivamus euismod
                molestie porttitor. Sed at consequat est. Curabitur lobortis accumsan ipsum, nec tincidunt libero scelerisque in. Vivamus mollis, justo ut vehicula ultrices, nulla nisl maximus justo, quis condimentum lectus diam id odio. Nunc ultrices, nunc sed convallis
                eleifend, massa tellus congue tortor, sed euismod purus orci id arcu. Duis elementum, quam a sodales egestas, quam dolor hendrerit sapien, sit amet sollicitudin mauris erat non nunc.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>
        </TabPanel>
        <TabPanel className={classes.tabContentContainer} value={value} index={4}>
          <div className={classes.tabContentHead}>
            <h3>Support Us</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac,
              condimentum eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Curabitur lobortis tortor non vehicula ultricies. Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque.
              </p>
              <p>
                In tincidunt lobortis nisl a viverra. Duis tempus rhoncus mauris in auctor. Ut at augue sed purus consectetur efficitur. Proin lobortis scelerisque neque, eu varius dui. Etiam mollis massa vel neque venenatis fringilla. Cras ac odio elit. Vivamus euismod
                molestie porttitor. Sed at consequat est. Curabitur lobortis accumsan ipsum, nec tincidunt libero scelerisque in. Vivamus mollis, justo ut vehicula ultrices, nulla nisl maximus justo, quis condimentum lectus diam id odio. Nunc ultrices, nunc sed convallis
                eleifend, massa tellus congue tortor, sed euismod purus orci id arcu. Duis elementum, quam a sodales egestas, quam dolor hendrerit sapien, sit amet sollicitudin mauris erat non nunc.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>
        </TabPanel>

        <TabPanel className={classes.tabContentContainer} value={value} index={5}>
          <div className={classes.tabContentHead}>
            <h3>How to use DEP better</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac,
              condimentum eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Curabitur lobortis tortor non vehicula ultricies. Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque.
              </p>
              <p>
                In tincidunt lobortis nisl a viverra. Duis tempus rhoncus mauris in auctor. Ut at augue sed purus consectetur efficitur. Proin lobortis scelerisque neque, eu varius dui. Etiam mollis massa vel neque venenatis fringilla. Cras ac odio elit.
                Vivamus euismod molestie porttitor. Sed at consequat est. Curabitur lobortis accumsan ipsum, nec tincidunt libero scelerisque in. Vivamus mollis, justo ut vehicula ultrices, nulla nisl maximus justo, quis condimentum lectus diam id odio. Nunc ultrices, nunc sed convallis eleifend, massa tellus congue tortor, sed euismod purus orci id arcu. Duis elementum, quam a sodales egestas, quam dolor hendrerit sapien, sit amet sollicitudin mauris erat non nunc.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>

          <div className={classes.tabContentHead}>
            <h3>What&#39;s New</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac, condimentum eleifend
              risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque. Curabitur lobortis tortor non vehicula ultricies.
              </p>
              <p>
                Curabitur tincidunt ac ligula a molestie. Aenean libero magna, dictum ut libero sit amet, maximus sagittis ligula. Quisque ultrices auctor feugiat. Maecenas tempus facilisis tellus sed tincidunt. Sed porttitor sapien nulla, ut vestibulum neque finibus eu. Ut tempus eget orci sit amet blandit. Mauris ullamcorper pellentesque vestibulum.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>

          <div className={classes.tabContentHead}>
            <h3>Testimonials</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac, condimentum
              eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Curabitur lobortis tortor non vehicula ultricies. Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque.
              </p>
              <p>
                In tincidunt lobortis nisl a viverra. Duis tempus rhoncus mauris in auctor. Ut at augue sed purus consectetur efficitur. Proin lobortis scelerisque neque, eu varius dui. Etiam mollis massa vel neque venenatis fringilla. Cras ac odio elit. Vivamus euismod molestie
                porttitor. Sed at consequat est. Curabitur lobortis accumsan ipsum, nec tincidunt libero scelerisque in. Vivamus mollis, justo ut vehicula ultrices, nulla nisl maximus justo, quis condimentum lectus diam id odio. Nunc ultrices, nunc sed convallis eleifend, massa
                tellus congue tortor, sed euismod purus orci id arcu. Duis elementum, quam a sodales egestas, quam dolor hendrerit sapien, sit amet sollicitudin mauris erat non nunc.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>

          <div className={classes.tabContentHead}>
            <h3>Taxonomy</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac,
              condimentum eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Curabitur lobortis tortor non vehicula ultricies. Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque.
              </p>
              <p>
                In tincidunt lobortis nisl a viverra. Duis tempus rhoncus mauris in auctor. Ut at augue sed purus consectetur efficitur. Proin lobortis scelerisque neque, eu varius dui. Etiam mollis massa vel neque venenatis fringilla. Cras ac odio elit. Vivamus euismod molestie
                porttitor. Sed at consequat est. Curabitur lobortis accumsan ipsum, nec tincidunt libero scelerisque in. Vivamus mollis, justo ut vehicula ultrices, nulla nisl maximus justo, quis condimentum lectus diam id odio. Nunc ultrices, nunc sed convallis eleifend,
                massa tellus congue tortor, sed euismod purus orci id arcu. Duis elementum, quam a sodales egestas, quam dolor hendrerit sapien, sit amet sollicitudin mauris erat non nunc.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>

          <div className={classes.tabContentHead}>
            <h3>Support Us</h3>
            <p className="tabBrief">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas aliquam tortor lobortis, efficitur orci ac, bibendum nulla. Maecenas suscipit metus ex, non pharetra orci rhoncus a. Vestibulum velit risus, ultrices a dignissim ac,
              condimentum eleifend risus. Suspendisse potenti. Ut nec efficitur metus, at molestie justo.
            </p>
          </div>
          <div className={classes.tabContent}>
            <div className={classes.tabContentText}>
              <p>
                Curabitur lobortis tortor non vehicula ultricies. Pellentesque purus metus, egestas eget mauris non, aliquam vestibulum purus. Curabitur id dignissim eros. Nam non augue non turpis mattis condimentum. Nunc ligula nibh, ultricies ut sagittis nec, molestie in neque.
              </p>
              <p>
                In tincidunt lobortis nisl a viverra. Duis tempus rhoncus mauris in auctor. Ut at augue sed purus consectetur efficitur. Proin lobortis scelerisque neque, eu varius dui. Etiam mollis massa vel neque venenatis fringilla. Cras ac odio elit. Vivamus euismod
                molestie porttitor. Sed at consequat est. Curabitur lobortis accumsan ipsum, nec tincidunt libero scelerisque in. Vivamus mollis, justo ut vehicula ultrices, nulla nisl maximus justo, quis condimentum lectus diam id odio. Nunc ultrices, nunc sed convallis
                eleifend, massa tellus congue tortor, sed euismod purus orci id arcu. Duis elementum, quam a sodales egestas, quam dolor hendrerit sapien, sit amet sollicitudin mauris erat non nunc.
              </p>
            </div>
            <figure>
              <img src="https://www.3ieimpact.org/sites/default/files/Support-us.jpg" alt="" />
            </figure>
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

ContentTypes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( ContentTypes );
